import { gql } from "@apollo/client";
const GET_LAST_ORDER = gql`
    query GET_LAST_ORDER( $id: ID ) {
        customer(id: $id) {
            id
            username
            shipping {
              state
              postcode
              phone
              lastName
              firstName
              country
              email
              company
              city
              address2
              address1
            }
            billing {
              address1
              address2
              city
              company
              country
              email
              lastName
              firstName
              phone
              postcode
              state
            }
            email
            lastOrder {
              status
              orderNumber
              paymentMethod
              paymentMethodTitle
              datePaid
              lineItems {
                edges {
                  node {
                    quantity
                    total
                    totalTax
                    subtotal
                    product {
                      image {
                        link
                      }
                      shortDescription
                      ... on VariableProduct {
                        id
                        name
                        price
                        salePrice
                      }
                      ... on ExternalProduct {
                        id
                        name
                        price
                        salePrice
                      }
                      ... on SimpleProduct {
                        id
                        name
                        salePrice
                        price
                      }
                      ... on GroupProduct {
                        id
                        name
                        price
                      }
                    }
                  }
                }
              }
              total
              totalTax
              subtotal
            }
          }
    }
`;

export default GET_LAST_ORDER;

