import React from "react"
import {Badge} from "react-bootstrap"
import {isUserLoggedIn,toLogin} from "../../../utils/functions"
const Carticon = (props) => {
  const handleCartClick = () =>{
    if(typeof props.handleToCart == 'function' ){
      props.handleToCart()
      return
    }
    if(isUserLoggedIn()){
      window.location.href="../../cart"
     }
     else{
       toLogin()
     }

  }
  return(
    <div className="topBreadscrumRight">
      <a onClick={handleCartClick}>
        <i className="iconfont icon-cart"></i>
        {props.totalQty!==0 ? <Badge>{props.totalQty}</Badge> : ""}
      </a>
    </div>
  )
}
export default Carticon
