import React, {useState,useEffect} from "react"
import HeardTopWrapper from "../components/header-wrapper"
import Layout from "../components/layout/index"
import PageContainer from "../components/page-container"
import CartIcon from "../components/cart/cart-icon-right"
import {Table,Container,Row,Col,Image,Alert} from "react-bootstrap"
import Link from "gatsby-link";
import img from "../images/blog.jpg"
import { Helmet } from 'react-helmet';
import GET_LAST_ORDER from "../queries/get-last-orders";
import { useQuery } from "@apollo/client";
import {authToLogin} from "../utils/functions"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {graphql} from "gatsby"
import moment from "moment"
const OrderDetail = () => {
  useEffect(() => {
    authToLogin()
  }, []);
  const {t} = useTranslation();
  let loginedUserID;
  if (typeof window !== 'undefined') {
    loginedUserID = localStorage.getItem('userID') ?  localStorage.getItem('userID') : null;
  }
  const [cartProduct, setCartProduct] = useState([]);
  const [datePaid, setDatePaid] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);
  const [subTotal, setSubTotal] = useState(null);
  const [totalTax, setTotalTax] = useState(null);
  const [billingAddress, setBillingAddress] = useState({});
  const [ userID, setuserID ] = useState(loginedUserID );
  const [orderNumber, setOrderNumber] = useState(null);
  const [paymentMethodTitle,setPaymentMethodTitle] = useState(null);
 
  //get cart data
  const { data, refetch } = useQuery(GET_LAST_ORDER, {
    variables: {
      id: userID,
    },
    onCompleted: () => {
      setBillingAddress(data.customer.billing)
      setSubTotal(data.customer.lastOrder.subtotal)
      setTotalPrice(data.customer.lastOrder.total)
      setTotalTax(data.customer.lastOrder.totalTax)
      setDatePaid(data.customer.lastOrder.datePaid)
      setCartProduct(data.customer.lastOrder.lineItems.edges)
      setOrderNumber(data.customer.lastOrder.orderNumber)
      setPaymentMethodTitle(data.customer.lastOrder.paymentMethodTitle)
    },
    onError: (error) => {
      console.log(error)
    },
  });

  return(
    <div>
      <HeardTopWrapper title={t('OrderDetail')}/>
      <Layout>
          <Helmet>
            <title>{t('myKitchenInspector')}｜ {t('OrderDetail')}</title>
          </Helmet>
          <PageContainer>
            <div className="topBreadscrum">
              <div className="topBreadscrumLeft"><Link to="/shop" className="top-nav">{t('productList')}</Link> &gt; {t('OrderDetail')}</div>
              <CartIcon />
            </div>
            <Alert variant="success" className="alert-custom">
              <i className="iconfont icon-round_check_fill"></i>  {t('paymentSuccessful')}
            </Alert> 
            <Container fluid className="checkout-container">
              <div className="order-detail">
                <Row>
                  <Col>
                    <h5>{t('orderNumber')}</h5>
                    <p>{orderNumber}</p>
                  </Col>
                  <Col>
                    <h5>{t('date')}</h5>
                    <p>{moment(datePaid).format('YYYY-MM-DD')}</p>
                  </Col>
                  <Col>
                    <h5>{t('paymentMethod')}</h5>
                    <p>{paymentMethodTitle}</p>
                  </Col>
                  <Col>
                    <h5>{t('shippingAddress')}</h5>
                    <div>
                      {
                        billingAddress ?
                        <div>
                          <p>{billingAddress.firstName} {billingAddress.lastName}</p>
                          <p>{billingAddress.company}</p>
                          <p>{billingAddress.address1}</p>
                          <p>{billingAddress.address2}</p>
                          <p>{billingAddress.city}</p>
                          <p>{billingAddress.state}</p>
                          <p>{billingAddress.postcode}</p>
                          <p>{billingAddress.country}</p>
                          <p>{billingAddress.phone}</p>
                          <p>{billingAddress.email} </p>
                        </div>
                        :""
                      }
                    </div>
                  </Col>
                </Row>
                
              </div>
              <Row>
              <h2>{t('itemsOrdered')}</h2>
              <Table responsive="md" className="table-custom">
                  <thead>
                    <tr>
                      <th>{t('products')}</th>
                      <th>{t('price')}</th>
                      <th>{t('quantity')}</th>
                      <th>{t('total')}</th>
                    </tr>
                  </thead>
                  <tbody>
                  { 
                    cartProduct.map((item, index) => {
                      return <tr key={`item_${index}`}>
                      <td>
                        <div className="product-info">
                          <div className="product-info-img">
                            <Image src={item.node.product.image ? item.node.product.image.link : img}/>
                          </div>
                          <div>
                            <h5>{item.node.product.name}</h5>
                          </div>
                        </div>
                      </td>
                      <td>{item.node.product.price || 0}</td>
                      <td>{item.node.quantity}</td>
                      <td>
                      {item.node.total || 0}
                      </td>
                    
                    </tr>
                    })
                  }
                  </tbody>
                </Table>
              </Row>
              <Row>
                <Col>
                  
                  <div>
                    <div className="">
                      <div className="cart-totals checkout-cart-totals">
                        <h3>{t('cartTotals')}</h3>
                        <div className="totals-warp">
                          <div className="flex">
                            <div>{t('subTotal')}</div>
                            <h5>{subTotal}</h5>
                          </div>
                          <div className="flex">
                            <div>{t('tax')}</div>
                            <h5>{totalTax}</h5>
                          </div>
                          <div className="flex">
                            <div><b>{t('total')}</b></div>
                            <h1>{totalPrice}</h1>
                          </div>
                        </div>
                        
                      
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>

            
            
          </PageContainer>
        </Layout>

  </div>

  )
}

export default OrderDetail
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
